import React from 'react';
import './CostumerAccounts.css';
import { TopNav } from '../TopNav.js';
import { fetchAuth } from '../../fetch-auth';
import Switch from "react-switch";
import { NavLink } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import exitImg from '../../assets/images/exit.png';
import edit from '../../assets/images/edit.png';
import lang from '../Language/language.js';

export class KonsultVy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section: "bolag",
            theUser: "",
            companies: [],
            chosenCompanies: [],
            usingDaughter: false,
            clickedCompany: '',
            activeCompany: '',
            acBounce: 'acStill',
            users: [],
            removeUser: "",
            mainCompanies: [],
            removeAccessComp: "",
            removeKoncern: false,
            //For PopUps
            popUpUser: false,
            popUpRemoveAccess: false
        }
    }

    back = () => {
        this.setState({ chosenCompanies: this.state.mainCompanies })
        this.setState({ usingDaughter: false })
    }

    selectCompany = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const company = event.target.id
        const selectIt = async () => {
            //First check if the clicked one is the same as now.
            const parsedData = JSON.parse(company) //Get the clicked company and id
            const clickedCompany = parsedData.company
            const clickedType = parsedData.type
            const clickedId = parsedData.companyId

            if (clickedType === "moder" && this.state.usingDaughter === false) {
                //Get the selected company daughter companies and set these to state
                let daughterCompanies = this.state.companies.filter(c => c.companyId_FK === clickedId)
                if (daughterCompanies.length > 1) {
                    this.setState({ chosenCompanies: daughterCompanies })
                    //Set state to use daughter companies, for render these instead of main ones
                    this.setState({ clickedCompany: clickedCompany })
                    this.setState({ usingDaughter: true })
                    return;
                }
            }

            if (clickedCompany === this.state.activeCompany) {
                this.setState({ acBounce: "acBounce" })
                let acBounce = () => { this.setState({ acBounce: "acStill" }) }
                setTimeout(acBounce, 600);
                return;
            }
            //If not the same, change it
            let response = await fetchAuth(`/api/r/selectCompanyConsultant`, 'POST', JSON.stringify({ data: company }));
            if (response.status === 200) {
                let data = await response.json();
                localStorage.setItem('localT', data.localToken)
                this.setState({ activeCompany: data.activeCompany })
                this.setState({ acBounce: "acBounce" })
                let acBounce = () => { this.setState({ acBounce: "acStill" }) }
                setTimeout(acBounce, 600);
                return;
            }
            if (response.status !== 200) {
                alert(language === "sv" ? 'Ett fel inträffade, prova igen.' : lang[language].somethingWrong)
                return;
            }
        }
        selectIt();
    }

    togglePopUpUser = (event) => {
        if (this.state.popUpUser) {
            this.setState({ popUpUser: false, removeUser: '' })
        } else {
            const dataset = event.target.dataset.txt
            this.setState({ popUpUser: true, removeUser: dataset })
        }
    }

    toggleRemovePop = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.popUpRemoveAccess) {
            this.setState({ popUpRemoveAccess: false, removeAccessComp: '' })
        } else {
            const dataset = event.target.dataset.txt
            const parsedData = JSON.parse(dataset)
            const { koncern, type, companyId } = parsedData
            let localT = localStorage.getItem('localT');
            let { comp } = jwt_decode(localT);
            if (companyId === comp) {
                alert(language === "sv" ? "Du kan inte ta bort access till ditt eget bolag." : lang[language].removeAccesOwnComp)
                return;
            }
            if (type === "moder" && koncern === "Yes") {
                this.setState({ removeKoncern: true })
                this.setState({ popUpRemoveAccess: true, removeAccessComp: dataset })
                return;
            } else {
                this.setState({ removeKoncern: false })
                this.setState({ popUpRemoveAccess: true, removeAccessComp: dataset })
            }
        }
    }

    companyRemoveAccess = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const dataset = event.target.dataset.txt //Tells us "Koncern" or "Bolag", so we know if we should remove access for one or many companies
        const companyInfo = this.state.removeAccessComp
        const parsedData = JSON.parse(companyInfo)
        const { company, type, companyId } = parsedData
        const data = {
            company: company,
            type: type,
            companyId: companyId,
            remove: dataset
        }

        const removeIt = async () => {
            let response = await fetchAuth(`/api/removeAccessKonsult`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
                return;
            } else if (response.status === 200) {
                alert("Åtkomst är nu borttagen!")
                const companies = this.state.companies
                const mainCompanies = this.state.mainCompanies
                const chosenCompanies = this.state.chosenCompanies
                if (dataset === "Bolag") {
                    let updateCompanies = companies.filter(c => c.dotterBolag != company)
                    let updateMainCompanies = mainCompanies.filter(c => c.dotterBolag != company)
                    let updateChosenCompanies = chosenCompanies.filter(c => c.dotterBolag != company)
                    this.setState({ companies: updateCompanies, mainCompanies: updateMainCompanies, chosenCompanies: updateChosenCompanies, popUpRemoveAccess: false, removeAccessComp: '' })
                    return;
                } else if (dataset === "Koncern") {
                    let updateCompanies = companies.filter(c => c.companyId_FK != companyId)
                    let updateMainCompanies = chosenCompanies.filter(c => c.companyId_FK != companyId)
                    let updateChosenCompanies = chosenCompanies.filter(c => c.companyId_FK != companyId)
                    this.setState({ companies: updateCompanies, mainCompanies: updateMainCompanies, chosenCompanies: updateChosenCompanies, popUpRemoveAccess: false, removeAccessComp: '', usingDaughter: false })
                    return;
                }

            }
        }
        removeIt();

    }

    userDelete = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        this.setState({ popUpUser: false });
        const dataset = this.state.removeUser
        const parsedData = JSON.parse(dataset) //Get the clicked company and id
        const { role, companyId, email, user } = parsedData
        if (role === "Admin") {
            alert(language === "sv" ? "Admin användare kan inte raderas." : lang[language].adminNotDelete)
            return;
        }
        let localT = localStorage.getItem('localT');
        let { name } = jwt_decode(localT);
        if (name == user) {
            alert(language === "sv" ? "Du kan inte radera din egen användare." : lang[language].cantDeleteOwn)
            return;
        } else {
            const removeIt = async () => {
                let response = await fetchAuth(`/api/userDeleteKonsult`, 'POST', JSON.stringify({ data: dataset }));
                // let data = await response.json();
                if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel. Försök igen." : lang[language].somethingWrong)
                } else if (response.status === 200) {
                    alert(language === "sv" ? "Användare borttagen." : lang[language].userRemoved)
                    const userState = this.state.users
                    let updatedUserList = userState.filter(u => u.userId_PK != user)
                    this.setState({ users: updatedUserList })
                }
            }
            removeIt();
            this.setState({ removeUser: '' })
        }
    }


    componentDidMount() {
        const loadCompanies = async () => {
            let response = await fetchAuth(`/api/loadConsultant`, 'POST');
            let data = await response.json();
            // let companies = data[0][0];
            let companies = data[0]
            console.log(companies)

            let users = data[1][0]
            let mainCompanies = companies.filter(c => c.type === "moder")
            //If there is no moder company:
            if (mainCompanies.length === 0) { mainCompanies = companies } //<--- NEW!!

            //If should only have access to a daughter company, then we need to add that to the mainCompanies as well
            const result = [];
            const grouped = {};
            // Group by companyId_FK
            companies.forEach(obj => {
                if (!grouped[obj.companyId_FK]) {
                    grouped[obj.companyId_FK] = [];
                }
                grouped[obj.companyId_FK].push(obj);
            });
            // Process each group
            Object.values(grouped).forEach(group => {
                const mother = group.find(obj => obj.type === "moder");
                if (mother) {
                    // If there is a "moder", push only the "moder" object
                    result.push(mother);
                } else {
                    // If no "moder", push all "daughter" objects
                    result.push(...group.filter(obj => obj.type === "daughter"));
                }
            });

            mainCompanies = result

            //Filter the user from the users data and then get activeCompany
            let localT = localStorage.getItem('localT');
            let { name, aComp } = jwt_decode(localT);
            let theUser = users.filter(u => u.userId_PK === name)
            let daUser = theUser[0].email

            //Add users company to users list. A litte bit of calculation:)
            let mComp = companies.filter(c => c.type === "moder")
            if (mComp[0] !== undefined) {
                let motherComp = mComp[0].dotterBolag
                for (let i = 0; i < users.length; i++) {
                    users[i].belongTo = motherComp
                }
            }
            this.setState({ companies: companies, activeCompany: aComp, users: users, theUser: daUser, chosenCompanies: mainCompanies, mainCompanies: mainCompanies })
        }
        loadCompanies();
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let companies = "Laddar företag.."

        if (this.state.companies.length > 0) {
            companies = this.state.chosenCompanies.map(company => {
                return <>
                    {this.state.usingDaughter === false ?
                        <div className="company">
                            <div className="removeCompany" onClick={this.toggleRemovePop}
                                data-txt={this.state.companies.filter(c => c.companyId_FK === company.companyId_FK).length > 1 ? `{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}", "koncern": "Yes"}` : `{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}", "koncern": "No"}`}
                            >x</div>
                            <span className="koncernText">{this.state.companies.filter(c => c.companyId_FK === company.companyId_FK).length > 1 ? "Koncern" : ""}</span>
                            <p
                                id={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`}
                                onClick={this.selectCompany}
                                className="companyText">
                                {company.dotterBolag}
                            </p>
                        </div>
                        :
                        <div className="company companyAnimation">
                            <div className="removeCompany" onClick={this.toggleRemovePop}
                                data-txt={this.state.companies.filter(c => c.companyId_FK === company.companyId_FK).length > 1 ? `{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}", "koncern": "Yes"}` : `{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}", "koncern": "No"}`}
                            >x</div>
                            <span style={{ marginLeft: 38 }} className="koncernText">{company.type === "moder" ? "Moderbolag" : ""}</span>
                            <p
                                id={`{"company": "${company.dotterBolag}", "companyId": ${company.companyId_FK}, "type": "${company.type}"}`}
                                onClick={this.selectCompany}
                                className="companyText">
                                {company.dotterBolag}
                            </p>
                        </div>}
                </>
            });
        }

        let users = ""

        if (this.state.users.length > 0) {
            users = this.state.users.map(user => {
                return <li className="liUsers">
                    <span className="liUserItem">{user.belongTo}</span>
                    <span className="liUserItem">{user.email}</span>
                    <span className="liUserItem">{user.role}</span>
                    <span onClick={this.togglePopUpUser} className="liUserItem liRemove" data-txt={`{"user": "${user.userId_PK}", "companyId": ${user.companyId_FK}, "role": "${user.role}", "email": "${user.email}"}`}>X</span>
                </li>
            });
        }

        return (
            <>
                <TopNav />
                {/*PopUp remove user */}
                <div className={this.state.popUpUser ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.togglePopUpUser} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}>{language === "sv" ? "Är du säker på att du vill ta bort denna användare?" : lang[language].sureDeleteUser}
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{language === "sv" ? "Användaren tas bort men data som är skapat av användaren behålls. Ta även bort bolaget för att ta bort all data." : lang[language].deletedUserNotComp}</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.togglePopUpUser}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                        <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} onClick={this.userDelete}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span>
                    </div>
                </div>
                {/*PopUp remove company */}
                <div className={this.state.popUpRemoveAccess ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 4, maxWidth: 580, minHeight: 290 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.toggleRemovePop} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}>{language === "sv" ? "Ta bort access till detta bolag?" : lang[language].removeAccessToThisComp}
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{language === "sv" ? "Ert konsultkonto inklusive alla dess användare kommer förlora access till detta bolag. Ingen data raderas och bolagets egna användare kan fortsatt komma åt bolaget via dess egna konto." : lang[language].consultRemoveOwnAccess}</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        {!this.state.removeKoncern
                            ? <div>
                                <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.toggleRemovePop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} data-txt="Bolag" onClick={this.companyRemoveAccess}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span>
                            </div>
                            : <div>
                                <div style={{ marginBottom: 40, marginBottom: 40, padding: "0px 30px", marginTop: -20, borderTop: "1px solid #d7d7d7", paddingTop: 10 }}>{language === "sv" ? "Detta är en koncern. Vill du ta bort access för hela bolaget eller enbart för det valda bolaget?" : lang[language].consultRemoveOwnAccessGroup}</div>
                                <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.toggleRemovePop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} data-txt="Bolag" onClick={this.companyRemoveAccess}>{language === "sv" ? "Bara bolaget" : lang[language].chooseC}</span>
                                <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} data-txt="Koncern" onClick={this.companyRemoveAccess}>{language === "sv" ? "Hela koncernen" : lang[language].chooseG}</span>
                            </div>
                        }
                    </div>
                </div>

                <NavLink exact to="/" className="goBack">{language === "sv" ? "Tillbaka" : lang[language].back}</NavLink>
                <div className="login-page">
                    <h1 style={{ marginTop: 120 }}>{language === "sv" ? "Konto" : lang[language].account}</h1>
                    <div className="selectSectionContainer">
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderTopRightRadius: 0, borderBottomRightRadius: 0, }} className={this.state.section === "bolag" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "bolag" })}>{language === "sv" ? "Bolag" : lang[language].company}</span>
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderLeft: "1px solid #eaeaea", borderRight: "1px solid #eaeaea", borderRadius: 0 }} className={this.state.section === "users" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "users" })}>{language === "sv" ? "Användare" : lang[language].users}</span>
                        <span style={{ width: 90, paddingTop: 15, paddingBottom: 15, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} className={this.state.section === "addNew" ? "selectWhat kontoActive" : "selectWhat"} onClick={() => this.setState({ section: "addNew" })}>{language === "sv" ? "Lägg till" : lang[language].personBenefitAdd}</span>
                    </div>

                    {/* Create company */}
                    <div className={this.state.section === "newUser" ? "users-container" : "hideComp"} style={{ height: 480, display: "inline-block" }}>
                        <h2 style={{ fontWeight: 800, fontSize: 22, marginBottom: 40 }}>Lägg till företag</h2>
                        <div className="form-container" style={{ marginTop: 0, paddingTop: 40, height: "auto", paddingBottom: 40 }}>
                            <form className="login-form">
                                <input className="form-input" value={this.state.company} type="text" placeholder="Företag" onChange={this.company} />
                                <button style={{ marginTop: 20 }} className="login-button" onClick={this.createUser}>Lägg till</button>
                            </form>
                        </div>
                    </div>

                    {/* Show companies */}
                    <div className={this.state.section === "bolag" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}>{language === "sv" ? "Du företräder" : lang[language].youRepresent}:</p>
                        <h2 className={this.state.acBounce}>{this.state.activeCompany}</h2>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        <h2 style={{ fontWeight: 800, fontSize: 19.6, marginBottom: 40 }}>{language === "sv" ? "Välj det bolag du vill företräda" : lang[language].chooseCompRepresent}</h2>
                        {this.state.usingDaughter === true ? <p style={{ marginTop: -30 }}><span className="backCompanyButton" onClick={this.back}>{language === "sv" ? "Tillbaka" : lang[language].back}</span>{language === "sv" ? "Visar alla bolag tillhörande: " : lang[language].showAllCompBelongingTo}<b>{this.state.clickedCompany}</b></p> : ""}
                        {companies}
                    </div>

                    {/* Show users */}
                    <div className={this.state.section === "users" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b", fontSize: 15.6 }}>{language === "sv" ? "Din användare" : lang[language].yourUser}:</p>
                        <h2 className={this.state.acBounce}>{this.state.theUser}</h2>
                        <p style={{ borderBottom: "1px solid #d7d8d8", marginBottom: 40 }}></p>

                        <h2 style={{ fontWeight: 800, fontSize: 19.6, marginBottom: 40 }}>{language === "sv" ? "Visar alla användare" : lang[language].allUsers}</h2>
                        <ul style={{ boxShadow: "0 3px 14px 0 rgb(0 0 0 / 6%)" }}>
                            <li className="liUsers liFilter">
                                <span className="liUserItem" style={{ fontSize: 16 }}>{language === "sv" ? "Företag" : lang[language].company}</span>
                                <span className="liUserItem" style={{ fontSize: 16 }}>{language === "sv" ? "Email" : lang[language].email}</span>
                                <span className="liUserItem" style={{ fontSize: 16 }}>{language === "sv" ? "Roll" : lang[language].role}</span>
                                <span style={{ background: "transparent" }} className="liUserItem liRemove">
                                    <img style={{ width: 20, position: "absolute", marginTop: -13, marginLeft: -10 }} src={edit} /></span>
                            </li>
                            {users}
                        </ul>
                    </div>

                    {/* Add users or company */}
                    <div className={this.state.section === "addNew" ? "users-container" : "hideComp"}>
                        <p style={{ marginBottom: 8, fontStyle: "italic", color: "#4c4b4b" }}>{language === "sv" ? "Behöver du skapa nytt bolag eller användare?" : lang[language].newUser}</p>
                        <a
                            className="goBack"
                            style={{ display: "block", position: "inherit", width: 110, margin: "auto", marginTop: 20 }}
                            href="mailto:kontakt@lonelys.se?subject=Lägga till användare/företag/kund&body=Hej! Vi skulle vilja lägga till en ny användare / ett nytt företag. Kan ni återkomma till oss?">
                            {language === "sv" ? "Kontakta oss" : lang[language].contactUs}
                        </a>
                    </div>

                </div>
                <div style={{ zIndex: 3 }} className={this.state.popUpUser ? "popUpFader" : "hide"}></div>
                <div style={{ zIndex: 3 }} className={this.state.popUpRemoveAccess ? "popUpFader" : "hide"}></div>
            </>
        )
    }
}