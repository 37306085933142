import React, { Component } from 'react';
import Chart from "react-apexcharts";
import './ChartSalaryStructure.css';
import lang from '../Language/language.js';

export class ChartSalaryStructure extends Component {
    constructor(props) {
        let language = localStorage.getItem('language') ?? 'sv';
        super(props);
        this.state = {
            legendSalRange: true,
            chartWidth: 500,
            options3: {
                /* tooltip: {
                     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                       var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                       return `<ul>
                               <li><b>Namn</b>: ${data.fullName}</li>
                               <li><b>Lön</b>: ${data.y} kr</li>
                               <li><b>År i org</b>: ${data.expNoJitter}</li>
                               <li><b>Ålder</b>: ${data.age}</li>
                               <li><b>Ort</b>: ${data.city}</li>
                               </ul>`
                     },
                   },*/
               /* annotations: { //This is to create lines inside the boxes
                    points: [
                        { // This is the range TOP
                            y: 60000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '60 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        {  // This is the midpoint
                            y: 45000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "#333333",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: "45 000", //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        { // This is the range BOTTOM
                            y: 40000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '40 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 22
                            }
                        },

                    ]
                },*/
                fill: {
                    opacity: 1,
                },
                stroke: {
                    // show: false,
                    //width: 0.5,
                    // width: 1.5,
                    width: [0.5, 2, 2, 2, 2, 2, 2], // 0.5 is the borders for the floating boxes
                    colors: ["#fff"]
                },
                //colors: ["#FF0000", "#FF0000","#FF0000", "#FF0000","#FF0000", "#FF0000"],
                grid: {
                    borderColor: "#e1e1e1",
                    position: 'front',
                    show: false,
                    padding: {
                        left: 0,
                        right: 0
                      }
                    /*  xaxis: {
                          lines: {
                              show: false
                          }
                      }*/
                },
                /* tooltip: {
                   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                     var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                     return `<ul>
                             <li><b>Namn</b>: ${data.fullName}</li>
                             <li><b>Lön</b>: ${data.y} kr</li>
                             <li><b>År i org</b>: ${data.expNoJitter}</li>
                             <li><b>Ålder</b>: ${data.age}</li>
                             <li><b>Ort</b>: ${data.city}</li>
                             </ul>`
                   },
                 },*/
                chart: {
                    height: 350,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: true
                    },
                    events: { // Change this to change the legend (series buttons) function
                        legendClick: function (chartContext, seriesIndex, config) {
                            // This toggles this.state.legendSalRange, it determes if annotations for lönespann should be shown or hidden.
                            const clickedLegendName = chartContext.w.config.series[seriesIndex].name;
                            if (clickedLegendName === "Lönespann") {
                                this.state.legendSalRange ? this.setState({ legendSalRange: false }) : this.setState({ legendSalRange: true })
                              //  this.updateChart()
                            }
                        }.bind(this)
                    }

                },
                states: {
                    hover: {
                        filter: {
                            type: 'none', // Removes hover effect
                        }
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                       // columnWidth: 10,
                        //floating: true, // Enable floating bars
                    },
                },
                /*markers: { //REMOVE THIS
                 strokeWidth: 3,
                 strokeColors: ["#000", "#cf1af2"],
                 //size: 5,  
               },*/
                //colors:["#000", "#cbb958"],
                markers: {
                    size: [2, 6],
                    hover: {
                        sizeOffset: 3
                      }
                },

                tooltip: {
                    shared: false,
                    intersect: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                        if (data.range === undefined) { //If undefined we are not looking at the range box, instead we look at an individual or p-value
                            if (data.p === undefined) { //If p is undefined, we are looking at an individual
                                return `<div class="tooltip">
                                    <div><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</div>
                                <div><b>${language === "sv" ? "Lön" : lang[language].salary}</b>: ${data.y}</div>
                                <div><b>${language === "sv" ? "Tillägg" : lang[language].benefits}</b>: ${data.addOn} ${data.addOn > 0 ? "(exkluderat)" : ""}</div>
                                <div><b>${language === "sv" ? "Befattning" : lang[language].position}</b>: ${data.positionTitle}</div>
                                <div><b>${language === "sv" ? "Lika arbete" : lang[language].equalWork}</b>: ${data.groupNameArbVardering}</div>
                                <div><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</div>
                                <div><b>${language === "sv" ? "År i org" : lang[language].yearsInOrg}</b>: ${data.experience}</div>
                                <div><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</div>
                                <div><b>${language === "sv" ? "Kön" : lang[language].gender}</b>: ${language === "sv" && data.gender === "woman" ? "Kvinna" : language === "sv" && data.gender === "man" ? "Man" : data.gender }</div>
                                 <div><b>${language === "sv" ? "Bolag" : lang[language].city}</b>: ${data.dotterBolagReal}</div>
                                </div>`;
                            } else { //If p is not undefined, we are looking at a "P" value
                                return `<div class="tooltip">${data.y}</div>`;
                            }
                        } else if (data.range === "hide") {return `<div></div>`}
                        else {
                            return `<div class="tooltip">
                            <div style="text-align: center; border-bottom: 1px solid lightgrey"><b>${language === "sv" ? "Nivå" : lang[language].level} ${data.x}</b></div>
                            <div style="padding-top: 4px; padding-bottom: 2px"><b>Max:</b> ${data.range[2]}</div>
                            <div style="padding-top: 2px; padding-bottom: 2px"><b>Mid:</b>  ${data.range[1]}</div>
                            <div style="padding-top: 2px"><b>Min:</b>  ${data.range[0]}</div>
                            </div>`;
                        }
                    }
                },
               /* xaxis: {
                    type: 'numeric',
                    tickAmount: 10,
                    max: 6,
                    min: 1,
                    categories: [1,2,3,4,5,6,7,9],
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },*/
                xaxis: {
                    tickAmount: 10,
                    type: 'numeric',
                    max: 6,
                    min: 1,
                    ticks: {
                      includeBounds: true,
                    },
                    labels: {
                      formatter: function (val) {
                        return parseFloat(val).toFixed(0)
                      }
                    }
                  },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },


            },
            series3: [
                {
                    name: "Lönespann",
                    type: 'bar',
                    //color: "#cdcccc",
                    data: [ // X tells is the level
                        { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                        { y: 40000, x: 2, fillColor: "#fff", range: [40000, 45000, 60000], }, // Bottom
                        { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                        { y: 60000, x: 5, range: [60000, 45000, 80000] },
                    ],
                },
                /* Marknadslöner kan visas såhär:  {
                      name: "Marknadslöner",
                      type: 'bar',
                      //color: "#cdcccc",
                      data: [ // X tells is the level
                          { y: 50000, x: 2, fillColor: "green" }, // Floating box between 20000-40000 for x-axis 1
                          { y: 40000, x: 2, fillColor: "#f7f7f7",  }, // Customize as per your requirement
                          { y: 60000, x: 2, fillColor: "transparent" }, // Customize as per your requirement
                      ],
                  },*/
                {
                    name: "Medarbetare",
                    data: [{ y: 30000, x: 2 }, { y: 35000, x: 2 }, { y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }, { y: 50000, x: 5 }],
                    type: 'scatter',
                },

                {
                    name: "P10",
                    type: 'line',
                    color: "#dda6a6",
                    data: [{ y: 25000, x: 1, p: "p" }, { y: 20000, x: 2, p: "p" }, { y: 35000, x: 3, p: "p" }, { y: 22500, x: 5, p: "p" },] //"P" is used to determe what type of item it is for tooltip
                },
                {
                    name: "P25",
                    type: 'line',
                    color: "#f6bdc7",
                    data: [{ y: 30000, x: 1, p: "p" }, { y: 40000, x: 2, p: "p" }, { y: 55000, x: 3, p: "p" }, { y: 92500, x: 5, p: "p" },]
                },

                {
                    name: "Median",
                    type: 'line',
                    color: "#8fa7e1",
                    data: [{ y: 35000, x: 1, p: "p" }, { y: 50000, x: 2, p: "p" }, { y: 65000, x: 3, p: "p" }, { y: 40500, x: 5, p: "p" },]
                },
                {
                    name: "P75",
                    type: 'line',
                    color: "#dbc191",
                    data: [{ y: 40000, x: 1, p: "p" }, { y: 58000, x: 2, p: "p" }, { y: 75000, x: 3, p: "p" }, { y: 97500, x: 5, p: "p" },]
                },
                {
                    name: "P90",
                    type: 'line',
                    color: "#9dc59d",
                    data: [{ y: 45000, x: 1, p: "p" }, { y: 60000, x: 2, p: "p" }, { y: 80000, x: 3, p: "p" }, { y: 102500, x: 5, p: "p" },]
                },
                /* {
                     name: "Medellön",
                     data: [{ y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }],
                     color: "#000000",
                     type: 'line',
                 },*/
            ],
        };
    }


    updateChart = () => {
        //Hides annotations
        this.setState({
            options3: {
                annotations: { //This is to create lines inside the boxes
                    points: [
                        { // This is the range TOP
                            y: 60000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '60 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        {  // This is the midpoint
                            y: 45000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                fillColor: "#333333",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: "45 000", //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        { // This is the range BOTTOM
                            y: 40000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '40 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 22
                            }
                        },

                    ]
                },
            }
        })
        //this.setState({options3: options3})
    }

    componentDidUpdate(prevProps) {
       // if (this.props !== prevProps) {
        if(  this.props.triggerChartUpdate !== prevProps.triggerChartUpdate) {
            /*this.setState({ series3: [] }, () => {
                this.runChart(); // Updates the chart
            })*/
          //  alert("updated")
         /* this.setState(prevState => ({
            options3: {
              ...prevState.options3,
              xaxis: {
                ...prevState.options3.xaxis,
                tickAmount: this.props.ranges.length // or any other value you want
              }
            }
          }));*/
          
          /*this.setState(prevState => ({
            options3: {
              ...prevState.options3,
              xaxis: {
                ...prevState.options3.xaxis,
               min: 1, 
                max: this.props.nrOfEqualentGroups, // or any other value you want
                type: 'numeric',
                tickAmount: this.props.nrOfEqualentGroups,
                stepSize: 1,
                categories: [1,2,3,4,5,6,7,8,9]
            }
            }
          }));*/
         /* 
          let howManyLevelsInSelection = this.props.ranges.length / 2
          let length = "70%" // This is percentage
          if(howManyLevelsInSelection < 7) {length = "10%"}

          this.setState(prevState => ({
            options3: {
                ...prevState.options3,
                plotOptions: {
                    ...prevState.options3.plotOptions,  // Spread the existing plotOptions object
                    bar: {                             // Ensure to include the bar property updates correctly
                        ...prevState.options3.plotOptions.bar,
                        horizontal: false,
                        columnWidth: length,
                    }
                }
            }
        }));*/

      
      
      
       /* this.setState(prevState => ({
            options3: {
                ...prevState.options3,
                xaxis: {  // Correctly spread and update the xaxis property
                    ...prevState.options3.xaxis,
                    tickAmount: 10,
                    type: 'numeric',
                    max: this.props.nrOfEqualentGroups,
                    min: 1,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0);
                        }
                    }
                }
            }
        }));*/

        let length = "80%" // This is percentage
       // if(howManyLevelsInSelection < 7) {length = "10%"}
      // var length = 20 + (60 / (1 + 30*Math.exp(-this.props.nrOfEqualentGroups /3)));


        this.setState(prevState => ({
            options3: {
                ...prevState.options3,
                plotOptions: {
                    ...prevState.options3.plotOptions,
                    bar: {
                        ...prevState.options3.plotOptions.bar,
                        horizontal: false,
                       // columnWidth: '100%'
                      //  distributed: false,
                  //      columnWidth: length, // Percentage
                    }
                },
                xaxis: {
                    ...prevState.options3.xaxis,
                    tickAmount: this.props.nrOfEqualentGroups,
                    type: 'numeric',
                    max: this.props.nrOfEqualentGroups,
                    stepSize: 1,
                    min: 1,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0);
                        }
                    }
                }
            }
        }));




         /* plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: 10,
                //floating: true, // Enable floating bars
            },
        },*/

            this.runChart(); // Updates the chart

        }
    }
    runChart() {
        let language = localStorage.getItem('language') ?? 'sv';
        // properties, use these to update the chart
        let p10 = this.props.p10
        let p25 = this.props.p25
        let median = this.props.median
        let p75 = this.props.p75
        let p90 = this.props.p90
        let employees = this.props.employees
        let ranges = this.props.ranges.slice()
        let nrOfEqualentGroups = this.props.nrOfEqualentGroups

       /* ranges = [ // X tells is the level
        { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
        { y: 40000, x: 2, fillColor: "#fff", range: [40000, 45000, 60000], }, // Bottom
        { y: 70000, x: 5, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
    ]*/

    // Buggfix. If ranges is empty or if there is only one x-value, it wont show in the chart, therefore, add an invisible range that is not the same x.
    // Buffix 2. We will always add invisible ranges for the number of equivalent groups to the chart, this makes sure the chart is the correct width. Commented out are the buggfix without creating for each x-value.
    for (let i = 1; i <= nrOfEqualentGroups; i++) {  ranges.push( { y: 0, x: i+1, fillColor: "transparent"} ) }
   /* let invisibleRanges = []
    if(ranges.length < 1) {
     //   ranges = [ { y: 0, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }]
     for (let i = 1; i <= nrOfEqualentGroups; i++) {
        invisibleRanges.push({ y: 0, x: i+1, fillColor: "transparent" });
    }
    ranges = invisibleRanges
    } else {
        for (let i = 1; i <= nrOfEqualentGroups; i++) {  ranges.push( { y: 0, x: i+1, fillColor: "transparent"} ) }
        // Get all unique x values.
      /*  const uniqueX = [...new Set(ranges.map(item => item.x))];
        if(uniqueX.length < 2) {
            let whatX = uniqueX[0]
            ranges = [ { y: 0, x: whatX-1, fillColor: "transparent", range: [40000, 45000, 60000], }]
        }*/
    //}
    // End of buggfix

        let addToSeries = [
            {
                name: language === "sv" ? "Lönespann" : lang[language].payRanges,
                type: 'bar',
                //color: "#cdcccc",
                data: ranges
            },
            /* Marknadslöner kan visas såhär:  {
                  name: "Marknadslöner",
                  type: 'bar',
                  //color: "#cdcccc",
                  data: [ // X tells is the level
                      { y: 50000, x: 2, fillColor: "green" }, // Floating box between 20000-40000 for x-axis 1
                      { y: 40000, x: 2, fillColor: "#f7f7f7",  }, // Customize as per your requirement
                      { y: 60000, x: 2, fillColor: "transparent" }, // Customize as per your requirement
                  ],
              },*/
            {
                name: language === "sv" ? "Medarbetare" : lang[language].employees,
                data: employees, 
                type: 'scatter',
            },

            {
                name: "P10",
                type: 'line',
                color: "#dda6a6",
                data: p10
            },
            {
                name: "P25",
                type: 'line',
                color: "#f6bdc7",
                data: p25
            },

            {
                name: "Median",
                type: 'line',
                color: "#8fa7e1",
                data: median
            },
            {
                name: "P75",
                type: 'line',
                color: "#dbc191",
                data: p75
            },
            {
                name: "P90",
                type: 'line',
                color: "#9dc59d",
                data: p90
            },
        ]


        // Skippa annotations sålänge. Tror inte det behövs då vi har tabell intill och visas vid hovring.

        // Also update the annotation lines. The array is created using the ranges variable 
        // First remove all x-value-duplicates, we already have the range for each x-value in the range property
       /* const encounteredX = {};  // Object to keep track of encountered x values
        const uniqueXRanges = ranges.filter(obj => {         // Filter the array to keep only the first object with each x value
            if (!encounteredX[obj.x]) {
                encounteredX[obj.x] = true; // Mark the x value as encountered
                return true; // Keep this object
            }
            return false; // Discard this object
        });

        // Now create the array so it matches the chart
        let annotations = []
        for (let i = 0; i < uniqueXRanges.length; i++) {
            let top = {
                y: uniqueXRanges[i].range[2],
                x: uniqueXRanges[i].x + 0.66,
                marker: {
                    size: 1,
                    shape: "square",
                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                    strokeWidth: 0,
                    radius: 0,
                },
                borderColor: 'transparent',
                fillColor: 'transparent',
                label: {
                    borderWidth: 0,
                    text: uniqueXRanges[i].range[2], //set to undefined to hide the label
                    textAnchor: 'middle',
                    position: "left",
                    style: {
                        background: 'transparent',
                        cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                    },
                    offsetX: 31,
                    offsetY: 10
                }
            }
            let midpoint = {
                y: uniqueXRanges[i].range[1],
                x: uniqueXRanges[i].x + 0.66,
                marker: {
                    size: 1,
                    shape: "square",
                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                    fillColor: "#333333",
                    strokeWidth: 0,
                    radius: 0,
                },
                borderColor: 'transparent',
                fillColor: 'transparent',
                label: {
                    borderWidth: 0,
                    text: uniqueXRanges[i].range[1], //set to undefined to hide the label
                    textAnchor: 'middle',
                    position: "left",
                    style: {
                        background: 'transparent',
                        cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                    },
                    offsetX: 31,
                    offsetY: 10
                }
            }
            let bottom = {
                y: uniqueXRanges[i].range[0],
                x: uniqueXRanges[i].x + 0.66,
                marker: {
                    size: 1,
                    shape: "square",
                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                    fillColor: "transparent",
                    strokeWidth: 0,
                    radius: 0,
                },
                borderColor: 'transparent',
                fillColor: 'transparent',
                label: {
                    borderWidth: 0,
                    text: uniqueXRanges[i].range[0], //set to undefined to hide the label
                    textAnchor: 'middle',
                    position: "left",
                    style: {
                        background: 'transparent',
                        cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                    },
                    offsetX: 31,
                    offsetY: 22
                }
            }
            annotations.push(top)
            annotations.push(midpoint)
            annotations.push(bottom)
        }*/

            this.setState({ 
                series3: addToSeries
            }) 

    
    }

    render() {
        return (
            <div style={{ width: 500, display: "inline-block" }}>
                <Chart
                    options={this.state.options3}
                    series={this.state.series3}
                    type="line"
                    width={this.state.chartWidth}
                />
            </div>
        );
    }
}
