import React, { Component } from 'react';
import './SalaryRangeBar.css';
import personImg from '../../assets/images/personImg.png';
export class SalaryRangeBar extends Component {
    constructor() {
          super();
          this.state = {
              hoverPerson: false,
          }
      }
      showPop = () => {this.setState({hoverPerson: true})}
      hidePop = () => {this.setState({hoverPerson: false})}

  render() {
    let firstWidth = this.props.percentage
    let secondWidth = 100 - this.props.percentage

    let min = this.props.min == undefined || this.props.min == null ? 0 : this.props.min
    let midPoint = this.props.midPoint == undefined || this.props.midPoint == null ? 0 : this.props.midPoint
    let max = this.props.max == undefined || this.props.max == null ? 0 : this.props.max
    let percentage = this.props.percentage == undefined || this.props.percentage == null ? 0 : this.props.percentage
    let employeeSalary = this.props.employeeSalary == undefined || this.props.employeeSalary == null ? 0 : this.props.employeeSalary


    return (
      <div className="progress-bar-overview" style={{ width: 370, margin: "auto", position: "relative" }}>
        {/* First bar */}
        <div
          className="progress-overview"
          style={{
            borderRight: firstWidth === 100 || secondWidth === 100 ? "none" : "2.5px white solid",
            width: `${percentage}%`,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: firstWidth === 100 ? 5 : 0,
            borderBottomRightRadius: firstWidth === 100 ? 5 : 0,
          }}
        >
          <div className="rangeBarLeftText">
            <span className="rangeBarMiniText">{this.props.rangeBarWhat === "salaryRanges" ? "Min" : "Lägst"}</span>
            <span>{min}</span>
            </div>
        </div>

        {/* Second bar */}
        <div
          className="progress-overview"
          style={{
            borderRight: firstWidth === 100 || secondWidth === 100 ? "none" : "2.5px white solid",
            width: `${secondWidth}%`,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            background: "#65c5b6",
            borderTopLeftRadius: firstWidth === 0 ? 5 : 0,
            borderBottomLeftRadius: firstWidth === 0 ? 5 : 0,
          }}
        >
          <div className="rangeBarRightText">
          <span className="rangeBarMiniText">{this.props.rangeBarWhat === "salaryRanges" ? "Max" : "Högst"}</span>
          <span>{max}</span>
          </div>
        </div>

        {/* The employee img */}
        <img className="salary-marker" style={{ left: `${(employeeSalary - min) / (max - min) * 100}%` }} src={personImg} onMouseEnter={this.showPop} onMouseLeave={this.hidePop}/>
        {/* The employee hover popUp */}
        <div className={this.state.hoverPerson ? "personImgPopUp" : "hide"} style={{ left: `${(employeeSalary - min) / (max - min) * 100}%` }}>
        <span>Lön: </span>{employeeSalary}
        </div>
        {/* The midpoint text */}
        <div className="salary-midpoint-text" style={{ left: `${(midPoint - min) / (max - min) * 100}%` }}>
        <span className="rangeBarMiniText">{this.props.rangeBarWhat === "salaryRanges" ? "Mid" : "Snittlön"}</span>
        <span>{midPoint}</span>
        </div>

      </div>
    );
  }
}